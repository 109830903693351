<div class="row">
  <div class="col-lg-4 mb-4">
    <article class="card-service border border-secondary-subtle p-4 rounded-3 d-flex flex-column gap-3 h-100">
      <header class="d-flex align-items-center justify-content-between">
        <div class="rounded-3 text-primary">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-currency-dollar" viewBox="0 0 16 16">
            <path
              d="M4 10.781c.148 1.667 1.513 2.85 3.591 3.003V15h1.043v-1.216c2.27-.179 3.678-1.438 3.678-3.3 0-1.59-.947-2.51-2.956-3.028l-.722-.187V3.467c1.122.11 1.879.714 2.07 1.616h1.47c-.166-1.6-1.54-2.748-3.54-2.875V1H7.591v1.233c-1.939.23-3.27 1.472-3.27 3.156 0 1.454.966 2.483 2.661 2.917l.61.162v4.031c-1.149-.17-1.94-.8-2.131-1.718zm3.391-3.836c-1.043-.263-1.6-.825-1.6-1.616 0-.944.704-1.641 1.8-1.828v3.495l-.2-.05zm1.591 1.872c1.287.323 1.852.859 1.852 1.769 0 1.097-.826 1.828-2.2 1.939V8.73z" />
          </svg>
        </div>
        <div class="display-6 fw-medium">1</div>
      </header>
      <main>
        <p class="fs-4 fw-medium m-0">Vende tu inmueble y recibe el pago en menos de 20 días.</p>
      </main>
      <footer>
        <a class="btn btn-primary rounded-5 px-4" routerLink="/solviiforma">Pedir mi oferta</a>
      </footer>
    </article>
  </div>
  <div class="col-lg-4 mb-4">
    <article class="card-service border border-secondary-subtle p-4 rounded-3 d-flex flex-column gap-3 h-100">
      <header class="d-flex align-items-center justify-content-between">
        <div class="rounded-3 text-primary">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-house-heart" viewBox="0 0 16 16">
            <path d="M8 6.982C9.664 5.309 13.825 8.236 8 12 2.175 8.236 6.336 5.309 8 6.982" />
            <path
              d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.707L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.646a.5.5 0 0 0 .708-.707L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
          </svg>
        </div>
        <div class="display-6 fw-medium">2</div>
      </header>
      <main>
        <p class="fs-4 fw-medium m-0">Compra tu vivienda, recien remodelada.</p>
      </main>
      <footer>
        <a class="btn btn-primary rounded-5 px-4" routerLink="/propiedades">Explorar inmuebles</a>
      </footer>
    </article>
  </div>
  <div class="col-lg-4 mb-4">
    <article class="card-service border border-secondary-subtle p-4 rounded-3 d-flex flex-column gap-3 h-100">
      <header class="d-flex align-items-center justify-content-between">
        <div class="rounded-3 text-primary">
          <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-cash-coin" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8m5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0" />
            <path
              d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195z" />
            <path
              d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083q.088-.517.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1z" />
            <path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 6 6 0 0 1 3.13-1.567" />
          </svg>
        </div>
        <div class="display-6 fw-medium">3</div>
      </header>
      <main>
        <p class="fs-4 fw-medium m-0">SolviiFin - Solicita un crédito a partir del valor de tu propiedad.</p>
      </main>
      <footer>
        <button class="btn btn-primary rounded-5 px-4 opacity-25 text-light" [disabled]="true">Muy
          pronto...</button>
      </footer>
    </article>
  </div>
</div>
