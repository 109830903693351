<main-header></main-header>

<section class="hero hero-overlay d-flex flex-column justify-content-center py-5 bg-primary">
  <div class="container position-relative py-5">
    <div class="col-lg-7 d-flex flex-column justify-content-center">
      <h1 class="hero-title mb-3 text-light display-6 fw-medium"
        [innerHTML]="(pageData[0]) ? (pageData[0][0]) ? pageData[0][0].value : '' : ''">Centro de ayuda Solviing.</h1>
      <p class="hero-text mb-0 fs-5 text-light"
        [innerHTML]="(pageData[0]) ? (pageData[0][1]) ? pageData[0][1].value : '' : ''">¿Buscas ayuda? Podemos ofrecerte
        nuestro catálogo de soluciones. También no olvides que siempre puedes contactarnos en nuestras lineas Solviing.
      </p>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <div class="px-4 mb-4">
      <p class="text-dark fs-2 fw-medium py-0 section-title">Atención al cliente</p>

      <div class="row">
        <div class="col-lg-6">
          <div class="mb-3">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Monterrey Centro, Nuevo León</p>
              <p class="d-flex align-items-center mb-2 gap-3"> <i class="bi bi-telephone-fill fs-4"></i>
                <span>+(811) 015-8441</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Padre Mier 439 Ote Locales BYC, Zona Centro,
                  Monterrey, Nuevo León C.P: 64000</span>
              </p>
            </article>
          </div>

          <div class="mb-3">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Monterrey García, Nuevo León</p>
              <p class="d-flex align-items-center mb-2 gap-3">
                <i class="bi bi-telephone-fill fs-4"></i> <span>+(811) 360-1234</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Real de Minas 510, Local 24, Valle de Lincoln, Nuevo
                  León C.P: 66026</span>
              </p>
            </article>
          </div>

          <div class="mb-3">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Tijuana, Baja California</p>
              <p class="d-flex align-items-center mb-2 gap-3">
                <i class="bi bi-telephone-fill fs-4"></i> <span>+(664) 127-4149</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Plaza Comercial Monarca Locales C-40 y C-41, Tijuana,
                  B.C.</span>
              </p>
            </article>
          </div>

          <div class="mb-3">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Mexicali, Baja California</p>
              <p class="d-flex align-items-center mb-2 gap-3">
                <i class="bi bi-telephone-fill fs-4"></i> <span>+(686) 516-5781</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Carretera San Luis y Río San Ángel Local 1-B Plaza
                  Pedregal Fraccionamiento Valle del Pedregal C.P: 21395</span>
              </p>
            </article>
          </div>

          <div class="mb-3">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Ciudad de México, México</p>
              <p class="d-flex align-items-center mb-2 gap-3">
                <i class="bi bi-telephone-fill fs-4"></i> <span>+(52) 56 1002 9410</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Paseo de las Palmas 765 interior 704, Lomas de
                  Chapultepec. CDMX C.P: 1100</span>
              </p>
            </article>
          </div>

          <div class="">
            <article class="border border-secondary-subtle p-3 rounded-3 d-flex flex-column h-100">
              <p class="fs-6 fw-medium mb-3">Estado de México, México</p>
              <p class="d-flex align-items-center mb-2 gap-3">
                <i class="bi bi-telephone-fill fs-4"></i> <span>+(561) 995-2780</span>
              </p>
              <p class="d-flex m-0 gap-3">
                <i class="bi bi-geo-alt-fill fs-4"></i> <span>Macroplaza Tecámac Local H-7 Manzana 001, Los Héroes
                  Tecámac, C.P. 55764 Ojo de Agua, Estado de México. </span>
              </p>
            </article>
          </div>
        </div>

        <div class="col-lg-6 d-none d-lg-block">
          <figure class="m-0 w-100 h-100">
            <img class="w-100 h-100 object-fit-cover" src="{{hero}}" alt="">
          </figure>
        </div>
      </div>
    </div>

    <div ngbAccordion>
      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 pb-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][0]) ? pageData[2][0].value : '' : ''">
            ¿Qué es Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][1]) ? pageData[2][1].value : '' : ''">Somos una empresa
                innovadora dedicada a transformar el mercado de la vivienda económica en México. Mediante nuestra
                plataforma comercial y digital, ofrecemos soluciones para la compra, venta y financiamiento de vivienda
                con un fuerte compromiso social.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][2]) ? pageData[2][2].value : '' : ''">
            ¿Cuál es la misión de Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][3]) ? pageData[2][3].value : '' : ''">Nuestra misión es
                proporcionar opciones de vivienda accesibles, transparentes y eficientes. Estamos comprometidos con
                mejorar la calidad de vida de las comunidades en México, ofreciendo servicios de alta calidad y
                promoviendo la confianza
                y la transparencia en todas nuestras operaciones.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][4]) ? pageData[2][4].value : '' : ''">
            ¿Qué servicios ofrece Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][5]) ? pageData[2][5].value : '' : ''">
                Solviing ofrece los siguientes servicios con un enfoque en el impacto social:
                <ul>
                  <li>Compra y Venta de Viviendas: Compramos y vendemos viviendas económicas en las principales ciudades
                    de México (Tijuana, Mexicali, Monterrey, CDMX y Estado de México).</li>
                  <li>Gestión de Portafolios: Trabajamos en colaboración con propietarios de portafolios de viviendas,
                    bancos hipotecarios y el público en general para vender sus viviendas. Apoyamos en algunos casos con
                    procedimientos legales para asegurar las condiciones de posesión, rehabilitaciones y
                    acondicionamiento de vivienda para su pronta comercialización.</li>
                  <li>Soluciones Financieras: A partir de 2025 ofreceremos financiamientos de liquidez con garantía
                    hipotecaria con condiciones flexibles para mejoras en el hogar, inversiones personales y más,
                    promoviendo el desarrollo económico de las familias.</li>
                </ul>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][6]) ? pageData[2][6].value : '' : ''">
            ¿Cómo funciona el proceso de compra en Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][7]) ? pageData[2][7].value : '' : ''">Compramos viviendas
                en las principales ciudades de México con y sin adeudos hipotecarios. Nos encargamos del pago de
                adeudos, todas las reparaciones y procedimientos legales necesarios para preparar las viviendas para la
                venta, asegurando condiciones óptimas y dignas para los futuros propietarios. Nuestra promesa de compra
                es de menos de 20 días.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][8]) ? pageData[2][8].value : '' : ''">
            ¿Qué pasa si la oferta de Solviing no cumple mis expectativas?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][9]) ? pageData[2][9].value : '' : ''">En Solviing estamos
                dispuestos a que obtengas lo máximo por la venta de tu vivienda acorde a las condiciones físicas y
                financieras en las que se encuentra. Si la oferta que te muestra nuestra página no te convence, puedes
                contactarnos y uno de nuestros asesores te atenderá personalmente.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][10]) ? pageData[2][10].value : '' : ''">
            ¿Qué es SolviiFin?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][11]) ? pageData[2][11].value : '' : ''">SolviiFin es
                nuestra Fintech de préstamos con garantía hipotecaria que se lanzará en 2025. Ayudaremos a los
                propietarios de vivienda con empleos formales e informales a acceder a fondos adicionales para diversos
                propósitos, ofreciendo opciones de financiamiento flexibles. Adicionalmente ofreceremos préstamos para
                compra de inmuebles en México a trabajadores transfronterizos, apoyando el bienestar económico y social
                de las familias.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][12]) ? pageData[2][12].value : '' : ''">
            ¿Qué tipo de viviendas compra Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][13]) ? pageData[2][13].value : '' : ''">Nos especializamos
                en la compra de viviendas usadas con un precio inferior a $1.5 millones de pesos en las principales
                ciudades de México, contribuyendo a la mejora de las condiciones de vida y a la revitalización de
                comunidades. Compramos viviendas aunque tengan adeudos o se encuentren en procesos legales.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][14]) ? pageData[2][14].value : '' : ''">
            ¿Solviing cobra por asesoría y consultas?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][15]) ? pageData[2][15].value : '' : ''">No, en Solviing
                creemos en la importancia de la accesibilidad y la confianza. Por eso, no cobramos por asesoría ni
                consultas. Estamos aquí para ayudarte y brindarte toda la información que necesites sin costo alguno. No
                somos intermediarios ni hacemos traspasos de créditos Infonavit o bancarios.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][16]) ? pageData[2][16].value : '' : ''">
            ¿Cómo contribuye Solviing al impacto social en las comunidades?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][17]) ? pageData[2][17].value : '' : ''">Solviing se
                compromete a mejorar las condiciones de vivienda en México, revitalizando comunidades y asegurando que
                cada vivienda que compramos y vendemos esté en condiciones óptimas y dignas. También ofrecemos
                financiamiento que apoya el desarrollo económico de las familias, contribuyendo al bienestar social.
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][18]) ? pageData[2][18].value : '' : ''">
            ¿Dónde opera actualmente Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][19]) ? pageData[2][19].value : '' : ''">Actualmente,
                Solviing opera en cinco ciudades de México (Tijuana, Mexicali, Monterrey, CDMX y Estado de México).
                Nuestro
                objetivo es expandir nuestras operaciones a 13 ciudades con más de 10 millones de empleos formales,
                impactando
                positivamente en más comunidades.</div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][20]) ? pageData[2][20].value : '' : ''">
            ¿Cómo aprovecha Solviing el nearshoring?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][21]) ? pageData[2][21].value : '' : ''">Solviing aprovecha
                las oportunidades de nearshoring fortaleciendo su presencia en las ciudades fronterizas más
                importantes de México. El beneficio de la proximidad con Estados Unidos y las ventajas comerciales que
                esto ofrece, nos
                fortalece como empresa y nos ayuda a diseñar más soluciones de vivienda para la comunidad binacional.
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div ngbAccordionItem class="border-0 mb-2">
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton class="shadow-none bg-transparent text-dark fs-3 fw-medium py-0 py-2"
            [innerHTML]="(pageData[2]) ? (pageData[2][22]) ? pageData[2][22].value : '' : ''">
            ¿Cómo puedo ponerme en contacto con Solviing?
          </button>
        </h2>
        <div ngbAccordionCollapse class="">
          <div ngbAccordionBody>
            <ng-template>
              <div [innerHTML]="(pageData[2]) ? (pageData[2][23]) ? pageData[2][23].value : '' : ''">Puedes contactarnos
                a través de nuestro formulario de contacto en la página web o visitando nuestras
                oficinas en las principales ciudades de México. Estamos aquí para ayudarte y brindarte toda la
                información que necesites sin costo alguno.</div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>

<!-- <section>
  <div class="container">
    <div class="text-bg-light text-success rounded-3 p-5 text-center">
      <h3 class="fs-1 mb-0 section-title">Solviiamigo te responde.</h3>
      <p class="fs-5 fw-light mb-4">Siempre estamos listos para atenderte.</p>
      <div class="mt-2">
        <a href="https://" target="_blank" class="btn btn-success rounded-5 px-4 py-2">
          <i class="bi bi-whatsapp lh-1 fs-4 align-bottom me-1"></i> Comenzar a platicar por WhatsApp
        </a>
      </div>
    </div>
  </div>
</section> -->

<main-footer></main-footer>