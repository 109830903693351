<main-header></main-header>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold" style="letter-spacing: 3px;">No te lleva mas de 3 minutos.</p>
    <h3 class="fs-1 section-title">Compramos tu inmueble</h3>
    <p class="fs-5 fw-light mb-4">Cuéntanos acerca de tu propiedad, la ubicación, tamaño, número de habitaciones y
      baños. Dejanos tus datos de contacto y nosotros valuaremos tu inmueble. Si cumples con los requisitos, te daremos
      una oferta inmediata.</p>

    <div class="col-lg-8">
      <solviiforma></solviiforma>
    </div>
  </div>
</section>

<section>
  <div class="container">
    <need-help></need-help>
  </div>
</section>

<main-footer></main-footer>
