<google-map #googleMap width="100%" height="100%" [options]="mapOptions" [center]="mapCenter" [zoom]="mapZoom"
  [mapId]="mapId" (zoomChanged)="zoomChanged()">
  <map-marker-clusterer [imagePath]="markerClustererImagePath">
    @for (item of mapMarkers; track $index) {
    @if( markerClustererShow === false ) {
    <map-marker #marker="mapMarker" [position]="item.position" [title]="item.address"
      (mapClick)="openInfoWindow(marker, item)" />
    }

    @if( markerClustererShow === true ) {
    <map-advanced-marker #marker="mapAdvancedMarker" [position]="item.position"
      (mapClick)="openInfoWindow(marker, item)" />
    }
    }
  </map-marker-clusterer>

  <map-info-window (closeclick)="closeInfoWindow()">
    <h6>{{this.infoWindowData.address}}</h6>
    <h6>{{this.infoWindowData.state}}, {{this.infoWindowData.municipality}}.</h6>
    <p class="d-inline-block"><span>Folio {{this.infoWindowData.foil}}</span> - <span>{{this.infoWindowData.price |
        currency: 'MXN'}} MXN</span> - <span>{{this.infoWindowData.type}}</span> -
      <span>{{this.infoWindowData.status}}</span> - <span>{{this.infoWindowData.area}}m2 Área -
        {{this.infoWindowData.room}} Recam. - {{this.infoWindowData.bath}} Baños</span>
    </p>
  </map-info-window>
</google-map>
