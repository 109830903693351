import { Routes } from '@angular/router';
import { SolviiformaPage } from './solviiforma.component';

export const routes: Routes = [
  {
    path: 'solviiforma',
    component: SolviiformaPage,
    title: 'Compramos tu inmueble en menos de 20 días.'
  },
];
