import { Component } from '@angular/core';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { GalleriaModule } from 'primeng/galleria';
import { GoogleMapsModule } from '@angular/google-maps';
import { NeedHelpComponent } from '@components/need-help/need-help.component';
import { ActivatedRoute } from '@angular/router';
import { RealEstateService } from '@services/realestate.service';

@Component({
  selector: 'app-property-details',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    GalleriaModule,
    NgbAccordionModule,
    GoogleMapsModule,
    NeedHelpComponent
  ],
  templateUrl: './property-details.component.html',
  styleUrl: './property-details.component.scss'
})
export class PropertyDetailsPage {
  public responsiveGalleryOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5
    },
    {
      breakpoint: '768px',
      numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
  public images: { itemImageSrc: string, thumbnailImageSrc?: string }[] = [];
  public property: any = undefined

  constructor(
    private activatedRoute: ActivatedRoute,
    private realEstateService: RealEstateService
  ) { }

  async ngOnInit() {
    this.activatedRoute.params.subscribe(async routeParams => {
      await this.realEstateService.getPropertyDetails(routeParams['slug']).then((response: any) => {
        this.property = response.content

        this.images = []

        if (this.property.image?.name != null) {
          this.images.push({ itemImageSrc: this.property.image?.uri })
        } else {
          this.images.push({ itemImageSrc: './assets/images/media-placeholder.png' })
        }

        if (this.property.gallery != null) {
          for (const item of this.property.gallery) {
            this.images.push({ itemImageSrc: item.uri })
          }
        }
      })
    });
  }
}
