import { Component } from '@angular/core';

@Component({
  selector: 'need-help',
  standalone: true,
  imports: [],
  templateUrl: './need-help.component.html',
  styleUrl: './need-help.component.scss'
})
export class NeedHelpComponent {

}
