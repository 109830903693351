<main-header></main-header>

<section class="hero hero-overlay d-flex flex-column justify-content-center py-5"
  style="background-image: url({{hero}});">
  <div class="container position-relative">
    <div class="col-lg-7 d-flex flex-column justify-content-center">
      <h1 class="hero-title mb-3 text-light display-6 fw-medium"
        [innerHTML]="(pageData[0]) ? (pageData[0][0]) ? pageData[0][0].value : '' : ''">CON SOLVIING ES MUY FÁCIL <br>
        VENDER TU CASA HOY</h1>
      <p class="hero-text mb-4 fs-5 text-light"
        [innerHTML]="(pageData[0]) ? (pageData[0][1]) ? pageData[0][1].value : '' : ''">Valuamos tu propiedad ¡ahora!
      </p>

      <div class="d-flex gap-3 justify-content-between text-bg-light rounded-5 p-2" role="button"
        routerLink="/solviiforma">
        <p class="d-flex align-items-center justify-content-center m-0 ps-4 text-muted gap-3">
          <i class="bi bi-pin-map fs-4"></i> <span class="opacity-50"
            [innerHTML]="(pageData[0]) ? (pageData[0][2]) ? pageData[0][2].value : '' : ''">Escribe tu
            dirección...</span>
        </p>
        <button class="btn btn-primary rounded-5 px-5 py-3"
          [innerHTML]="(pageData[0]) ? (pageData[0][3]) ? pageData[0][3].value : '' : ''">Solicitar oferta</button>
      </div>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center opacity-50 mb-5" style="letter-spacing: 3px;"
      [innerHTML]="(pageData[1]) ? (pageData[1][0]) ? pageData[1][0].value : '' : ''">Vender tu casa con Solviing es muy
      fácil</p>

    <div class="row">
      <div class="col-lg-7 d-flex flex-column justify-content-center">
        <p class="fs-5 mb-2 opacity-50 text-uppercase"
          [innerHTML]="(pageData[1]) ? (pageData[1][1]) ? pageData[1][1].value : '' : ''">Paso a paso</p>
        <h3 class="fs-1 mb-4 section-title"
          [innerHTML]="(pageData[1]) ? (pageData[1][2]) ? pageData[1][2].value : '' : ''">¿Cómo vender tu propiedad?
        </h3>
        <div class="d-flex flex-column gap-5">
          <div class="d-flex gap-3">
            <div
              class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
              style="width: 40px; height: 40px;">1</div>
            <div class="d-flex flex-column w-100">
              <div class="fs-4 fw-medium" style="line-height: 40px;"
                [innerHTML]="(pageData[1]) ? (pageData[1][3]) ? pageData[1][3].value : '' : ''">Cuéntanos acerca de tu
                vivienda.</div>
              <p class="fs-5 fw-light m-0"
                [innerHTML]="(pageData[1]) ? (pageData[1][4]) ? pageData[1][4].value : '' : ''">Nos ayuda conocer tu
                propiedad para ofrecerte la mejor opción.</p>
            </div>
          </div>
          <div class="d-flex gap-3">
            <div
              class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
              style="width: 40px; height: 40px;">2</div>
            <div class="d-flex flex-column w-100">
              <div class="fs-4 fw-medium" style="line-height: 40px;"
                [innerHTML]="(pageData[1]) ? (pageData[1][5]) ? pageData[1][5].value : '' : ''">Hagamos una cita.</div>
              <p class="fs-5 fw-light m-0"
                [innerHTML]="(pageData[1]) ? (pageData[1][6]) ? pageData[1][6].value : '' : ''">Si tu propiedad tiene
                las características que buscamos, un asesor Solviing se pondrá en contacto contigo para hacer una visita
                y platicar acerca de la oferta por tu casa.</p>
            </div>
          </div>
          <div class="d-flex gap-3">
            <div
              class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
              style="width: 40px; height: 40px;">3</div>
            <div class="d-flex flex-column w-100">
              <div class="fs-4 fw-medium" style="line-height: 40px;"
                [innerHTML]="(pageData[1]) ? (pageData[1][7]) ? pageData[1][7].value : '' : ''">Firmemos contrato.</div>
              <p class="fs-5 fw-light m-0"
                [innerHTML]="(pageData[1]) ? (pageData[1][8]) ? pageData[1][8].value : '' : ''">Si aceptas la oferta,
                podremos hacer oficial la firma del contrato ante Notario Público.</p>
            </div>
          </div>
          <div class="d-flex gap-3">
            <div
              class="align-items-center bg-primary d-flex flex-column fs-4 justify-content-center rounded-1 text-light"
              style="width: 40px; height: 40px;">4</div>
            <div class="d-flex flex-column w-100">
              <div class="fs-4 fw-medium" style="line-height: 40px;"
                [innerHTML]="(pageData[1]) ? (pageData[1][9]) ? pageData[1][9].value : '' : ''">Recibe tu pago de
                inmediato.</div>
              <p class="fs-5 fw-light m-0"
                [innerHTML]="(pageData[1]) ? (pageData[1][10]) ? pageData[1][10].value : '' : ''">Recibe el monto
                acordado de la oferta y nosotros las llaves de tu propiedad ¡un final feliz para todos!</p>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-5 d-none d-lg-block">
        <figure class="m-0 w-100 h-100">
          <img class="w-100 h-100"
            src="./assets/images/DALL·E 2024-07-15 12.31.03 - Realistic photo of a single Infonavit home unit in Mexico. The house features simple geometric lines, concrete construction, and a minimalistic design.webp"
            alt="">
        </figure>
      </div>

      <div class="col-lg-7 mt-5 text-center">
        <a class="btn btn-primary rounded-5 px-5 py-3" routerLink="/solviiforma"
          [innerHTML]="(pageData[1]) ? (pageData[1][11]) ? pageData[1][11].value : '' : ''">¡Empezar ahora!</a>
      </div>
    </div>
  </div>
</section>

<section class="py-5">
  <div class="container">
    <need-help></need-help>
  </div>
</section>

<section class="our-services py-5">
  <div class="d-none d-lg-block" style="height: 50px;"></div>
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center" style="letter-spacing: 3px;">¿Que
      hacemos en Solviing?</p>
    <h3 class="fs-1 mb-4 section-title text-center">Nuestros servicios</h3>

    <our-services></our-services>
  </div>
  <div class="d-none d-lg-block" style="height: 50px;"></div>
</section>

<section class="py-5">
  <div class="container">
    <p class="m-0 text-muted text-uppercase fw-bold text-center" style="letter-spacing: 3px;">Resolvemos tus dadas</p>
    <h3 class="fs-1 mb-4 section-title text-center">Preguntas frecuentes</h3>

    <div class="w-lg-75 mx-auto">
      <div ngbAccordion [closeOthers]="true">
        <div ngbAccordionItem [collapsed]="false" class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Qué es Solviing?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                Somos una empresa innovadora dedicada a transformar el mercado de la vivienda económica en México.
                Mediante nuestra plataforma comercial y digital, ofrecemos soluciones para la compra, venta y
                financiamiento de vivienda con un fuerte compromiso social.
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Qué servicios ofrece Solviing?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                Solviing ofrece los siguientes servicios con un enfoque en el impacto social:
                <ul>
                  <li>Compra y Venta de Viviendas: Compramos y vendemos viviendas económicas en las principales ciudades
                    de México (Tijuana, Mexicali, Monterrey, CDMX y Estado de México).</li>
                  <li>Gestión de Portafolios: Trabajamos en colaboración con propietarios de portafolios de viviendas,
                    bancos hipotecarios y el público en general para vender sus viviendas. Apoyamos en algunos casos con
                    procedimientos legales para asegurar las condiciones de posesión, rehabilitaciones y
                    acondicionamiento de vivienda para su pronta comercialización.</li>
                  <li>Soluciones Financieras: A partir de 2025 ofreceremos financiamientos de liquidez con garantía
                    hipotecaria con condiciones flexibles para mejoras en el hogar, inversiones personales y más,
                    promoviendo el desarrollo económico de las familias.</li>
                </ul>
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Cómo funciona el proceso de compra en Solviing?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                Compramos viviendas en las principales ciudades de México con y sin adeudos hipotecarios. Nos encargamos
                del pago de adeudos, todas las reparaciones y procedimientos legales necesarios para preparar las
                viviendas para la venta, asegurando condiciones óptimas y dignas para los futuros propietarios. Nuestra
                promesa de compra es de menos de 20 días.
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Qué es SolviiFin?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                SolviiFin es nuestra Fintech de préstamos con garantía hipotecaria que se lanzará en 2025. Ayudaremos a
                los propietarios de vivienda con empleos formales e informales a acceder a fondos adicionales para
                diversos propósitos, ofreciendo opciones de financiamiento flexibles. Adicionalmente ofreceremos
                préstamos para compra de inmuebles en México a trabajadores transfronterizos, apoyando el bienestar
                económico y social de las familias.
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Qué pasa si la oferta de Solviing no cumple mis expectativas?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                En Solviing estamos dispuestos a que obtengas lo máximo por la venta de tu vivienda acorde a las
                condiciones físicas y financieras en las que se encuentra. Si la oferta que te muestra nuestra página no
                te convence, puedes contactarnos y uno de nuestros asesores te atenderá personalmente.
              </ng-template>
            </div>
          </div>
        </div>
        <div ngbAccordionItem class="border-0 mb-2">
          <h2 ngbAccordionHeader>
            <button ngbAccordionButton class="bg-light shadow-none rounded-3">
              ¿Solviing cobra por asesoría y consultas?
            </button>
          </h2>
          <div ngbAccordionCollapse class="">
            <div ngbAccordionBody>
              <ng-template>
                No, en Solviing creemos en la importancia de la accesibilidad y la confianza. Por eso, no cobramos por
                asesoría ni consultas. Estamos aquí para ayudarte y brindarte toda la información que necesites sin
                costo alguno. No somos intermediarios ni hacemos traspasos de créditos Infonavit o bancarios.
              </ng-template>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="text-center mt-4">
      <a class="btn btn-outline-primary rounded-5 px-3 py-2" routerLink="/ayuda">Ir al centro de ayuda</a>
    </div>
  </div>
</section>

<main-footer></main-footer>