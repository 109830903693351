import { CurrencyPipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GmapComponent } from '@components/gmap/gmap.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { PropertyThumbComponent } from '@components/property-thumb/property-thumb.component';
import { DropdownModule } from 'primeng/dropdown';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { BlockUIModule } from 'primeng/blockui';
import { SelectButtonModule } from 'primeng/selectbutton';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SliderModule } from 'primeng/slider';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CatalogService } from '@services/catalog.service';
import { RealEstateService } from '@services/realestate.service';

@Component({
  selector: 'app-propiedades',
  standalone: true,
  imports: [
    MainHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    GmapComponent,
    ScrollPanelModule,
    PropertyThumbComponent,
    TagModule,
    CurrencyPipe,
    DropdownModule,
    ButtonModule,
    BlockUIModule,
    SelectButtonModule,
    NgbDropdownModule,
    SliderModule,
    InputTextModule,
    FloatLabelModule
  ],
  templateUrl: './propiedades.component.html',
  styleUrl: './propiedades.component.scss'
})
export class PropiedadesPage {
  constructor(
    private formBuilder: FormBuilder,
    private catalogService: CatalogService,
    private realEstateService: RealEstateService
  ) { }

  public mapClear = false
  public mapCenter!: { lat: number, lng: number }
  public mapMarkers: any[] = []
  public mapMarkersFilter: any[] = []

  async ngOnInit() {
    this.clearMap()

    await this.realEstateService.getProperties().then((response) => this.propertiesList = response.content)

    this.filter()
  }

  public filterPanelShow: boolean = false
  public styleListing: string = 'grid'

  public filterForm = this.formBuilder.group({
    location: [null],
    typeProperty: [null],
    rooms: [null],
    bath: [null],
    status: [null],
    price: [[0, 3500000]],
    orderby: ['price_asc'],
  })

  get filterLocation() { return this.filterForm.get('location') }
  set filterLocation(value: any) { this.filterLocation.setValue(value) }
  get filterTypeProperty() { return this.filterForm.get('typeProperty') }
  set filterTypeProperty(value: any) { this.filterTypeProperty.setValue(value) }
  get filterRoom() { return this.filterForm.get('rooms') }
  set filterRoom(value: any) { this.filterRoom.setValue(value) }
  get filterBath() { return this.filterForm.get('bath') }
  set filterBath(value: any) { this.filterBath.setValue(value) }
  get filterStatus() { return this.filterForm.get('status') }
  set filterStatus(value: any) { this.filterStatus.setValue(value) }
  get filterPrice() { return this.filterForm.get('price') }
  set filterPrice(value: any) { this.filterPrice.setValue(value) }
  get filterOrderby() { return this.filterForm.get('orderby') }
  set filterOrderby(value: any) { this.filterOrderby.setValue(value) }

  public propertiesList: any = []
  public propertiesListFilter: any = []

  public locationsList: object[] = [
    { name: 'Monterrey', value: 'Monterrey' },
    { name: 'Tijuana', value: 'Tijuana' },
    { name: 'Mexicali', value: 'Mexicali' },
    { name: 'CDMX', value: 'Ciudad de México' },
  ]
  public typePropertiesList: object[] = [
    { name: 'Casa habitación', value: 2 },
    { name: 'Departamento en condominio', value: 4 },
  ]
  public roomsList: object[] = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
  ]
  public bathList: object[] = [
    { name: 1, value: 1 },
    { name: 2, value: 2 },
    { name: 3, value: 3 },
  ]
  public statusList: object[] = [
    { name: 'Disponible', value: 2 },
    { name: 'Recuperación', value: 4 },
  ]
  public orderList: object[] = [
    { name: 'Menor precio', value: 'price_asc' },
    { name: 'Mayor precio', value: 'price_desc' },
  ]

  async filter() {
    this.propertiesListFilter = this.propertiesList

    if (this.filterLocation.value !== null) {
      let state = '';

      switch (this.filterLocation.value) {
        case 'Monterrey':
          state = 'Nuevo León';
          break;
        case 'Tijuana':
        case 'Mexicali':
          state = 'Baja California';
          break;
        case 'Ciudad de México':
          state = 'Ciudad de México';
          break;
      }

      await this.catalogService.getMunicipalities(state).then((response: any) => {
        response.map((item: any, key: number) => response[key] = item.normalize("NFD").replace(/[\u0300-\u036f]/g, ""))

        this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => response.includes(item.municipality))

        if (this.filterLocation.value === 'Tijuana' || this.filterLocation.value === 'Mexicali') {
          this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.municipality === this.filterLocation.value)
        }
      })
    }

    if (this.filterTypeProperty.value !== null) {
      this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.typeId === this.filterTypeProperty.value)
    }

    if (this.filterRoom.value !== null) {
      this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.room === this.filterRoom.value)
    }

    if (this.filterBath.value !== null) {
      this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.bath === this.filterBath.value)
    }

    if (this.filterStatus.value !== null) {
      this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.statusId === this.filterStatus.value)
    }

    if (this.filterPrice.value !== null) {
      this.propertiesListFilter = this.propertiesListFilter.filter((item: any) => item.price >= this.filterPrice.value[0] && item.price <= this.filterPrice.value[1])
    }

    switch (this.filterOrderby.value) {
      case 'price_asc':
      default:
        this.propertiesListFilter = this.propertiesListFilter.sort((low: any, high: any) => low.price - high.price);
        break;
      case 'price_desc':
        this.propertiesListFilter = this.propertiesListFilter.sort((low: any, high: any) => high.price - low.price);
        break;
    }

    this.mapMarkers = []

    await this.propertiesListFilter.map((item: any) => {
      this.mapMarkers.push({
        position: {
          lat: Number(item.latitude),
          lng: Number(item.longitude)
        },
        address: item.metaGoogleMaps.formatted_address,
        state: item.state,
        municipality: item.municipality,
        foil: item.foil,
        price: item.price,
        type: item.type,
        status: item.status,
        area: item.area,
        room: item.room,
        bath: item.bath
      })
    })
  }

  clearFilter() {
    this.filterForm.reset();
    this.filterPrice = [0, 3500000]
    this.filterOrderby = 'price_asc'
    this.clearMap()
    this.filter()
  }

  clearMap() {
    this.mapCenter = { lat: 24.4329327, lng: -102.0087475 };

    this.mapClear = true
    setTimeout(() => this.mapClear = false);
  }

  onFilterLocationChange() {
    if (this.filterLocation.value !== null) {
      let state = '';

      switch (this.filterLocation.value) {
        case 'Monterrey':
          state = 'Monterrey, Nuevo León';
          break;
        case 'Tijuana':
          state = 'Tijuana, Baja California';
          break;
        case 'Mexicali':
          state = 'Mexicali, Baja California';
          break;
        case 'Ciudad de México':
          state = 'Ciudad de México';
          break;
      }

      this.catalogService.getAddress(`${state}, México`).then(response => {
        this.mapCenter = { lat: response[0].geometry.location.lat, lng: response[0].geometry.location.lng }
      })
    } else {
      this.clearMap()
    }

    this.filter()
  }

  onFilterTypePropertyChange() {
    this.filter()
  }

  onFilterRoomChange() {
    this.filter()
  }

  onFilterBathChange() {
    this.filter()
  }

  onFilterStatusChange() {
    this.filter()
  }

  onFilterPriceChange() {
    this.filter()
  }

  onFilterOrderbyChange() {
    this.filter()
  }

  onOpenInfoWindow(foil: any) {
    this.propertiesListFilter = this.propertiesList.filter((item: any) => item.foil === foil)
  }

  onCloseInfoWindow(event: any) {
    this.filter()
  }
}
