@if (this.isSaving === false && this.validationPurchaseTransaction === null) {
<form [formGroup]="form">
  <p-stepper orientation="vertical">
    <p-stepperPanel>
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <p class="text-primary fs-5 m-0" (click)="onClick.emit()">Paso 1 de 3: Ubicación</p>
      </ng-template>
      <ng-template pTemplate="content" let-nextCallback="nextCallback" let-index="index">
        <p class="fw-bold fs-4 mb-4">Ahora busquemos tu inmueble</p>

        <div class="row">
          <div class="col-12">
            <div class="mb-3">
              <label for="state">Estado <span class="text-danger">*</span></label>

              <p-dropdown formControlName="state" [options]="states" optionLabel="name" optionValue="value"
                showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione" styleClass="w-100 h-50"
                (onChange)="changeState()">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label for="municipality">Municipio <span class="text-danger">*</span></label>

              <p-dropdown formControlName="municipality" [options]="municipalities" optionLabel="name"
                optionValue="value" showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione"
                styleClass="w-100 h-50" (onChange)="changeMunicipality()">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label for="address">Ingresa tu ubicación <span class="text-danger">*</span></label>

              <p-autoComplete formControlName="address" [suggestions]="addressSuggestions" showClear="true"
                (completeMethod)="addressAutocomplete($event)" field="description" styleClass="w-100 h-50"
                inputStyleClass="w-100 h-50" (onSelect)="onSelectAddress()">
                <ng-template let-item pTemplate="item">
                  <div class="py-3 border-bottom">
                    <h5>{{ item.structured_formatting.main_text }}</h5>
                    <p class="m-0">{{ item.structured_formatting.secondary_text }}</p>
                  </div>
                </ng-template>
              </p-autoComplete>
            </div>
          </div>

          @if (this.address.value !== null) {
          <div class="col-12">
            <hr class="mt-4 mb-5" />
            <p class="text-primary fw-normal fs-5 mb-4">¡Ya casi encontramos tu inmueble!</p>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="zipcode">Código postal <span class="text-danger">*</span></label>

              <input type="text" pInputText formControlName="zipcode" class="w-100 h-50" (change)="changeZipcode()" />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="colony">Colonia <span class="text-danger">*</span></label>

              <p-dropdown formControlName="colony" [options]="colonies" optionLabel="name" optionValue="value"
                showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione" styleClass="w-100 h-50">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          }

          @if ( this.state.valid && this.municipality.valid && this.address.valid && this.zipcode.valid &&
          this.colony.valid ) {
          <div class="col-6 offset-3 mt-4">
            <p-button label="Continuar" (onClick)="nextCallback.emit()" styleClass="rounded-5 w-100 py-3" />
          </div>
          }
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel>
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <p class="text-primary fs-5 m-0" (click)="onClick.emit()">Paso 2 de 3: Inmueble</p>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-nextCallback="nextCallback"
        let-index="index">
        <p class="fw-bold fs-4 mb-4">Cuentanos las carateristicas de tu inmueble</p>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="typeProperty">Tipo de inmueble <span class="text-danger">*</span></label>

              <p-dropdown formControlName="typeProperty" [options]="typeProperties" optionLabel="name"
                optionValue="value" showClear="true" [filter]="true" filterBy="name" placeholder="Seleccione"
                styleClass="w-100 h-50">
                <ng-template pTemplate="selectedItem" let-selectedOption>
                  <div>{{ selectedOption.name }}</div>
                </ng-template>
                <ng-template let-item pTemplate="item">
                  <div>{{ item.name }}</div>
                </ng-template>
              </p-dropdown>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="m2">Metros cuadrados <span class="text-danger">*</span></label>

              <p-inputNumber formControlName="m2" styleClass="w-100" class="w-100 h-50" inputId="minmaxfraction"
                mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="bedrooms">Número de recamaras <span class="text-danger">*</span></label>

              <p-inputNumber formControlName="bedrooms" styleClass="w-100" class="w-100 h-50" [min]="1" [max]="20" />
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="bathrooms">Número de baños <span class="text-danger">*</span></label>

              <p-inputNumber formControlName="bathrooms" styleClass="w-100" class="w-100 h-50" [min]="1" [max]="20"
                mode="decimal" [minFractionDigits]="1" [maxFractionDigits]="1" />
            </div>
          </div>

          <div class="col-12 mt-3">
            <div class="mb-3 d-flex align-items-center gap-3">
              <label for="debt">¿Tiene adeudo el inmueble?</label>

              <p-inputSwitch formControlName="debt" class="d-flex" />
            </div>
          </div>

          @if (this.debt.value === true) {
          <div class="col-6">
            <div class="mb-3">
              <label for="amountDebt">Monto del adeudo</label>

              <p-inputNumber formControlName="amountDebt" styleClass="w-100" class="w-100 h-50" [min]="0"
                mode="currency" currency="MXN" locale="es-MX" prefix="MXN" />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="creditNumberInfonavit">Número de crédito (solo infonavit)</label>

              <input type="text" pInputText formControlName="creditNumberInfonavit" class="w-100 h-50" />
            </div>
          </div>

          <div class="col-6">
            <div class="mb-3">
              <label for="cadastralKeyNumber">Número de clave catastral</label>

              <input type="text" pInputText formControlName="cadastralKeyNumber" class="w-100 h-50" />
            </div>
          </div>
          }

          <div class="col-6">
            <div class="mb-3">
              <label for="desiredAmount">Monto deseado por tu inmueble</label>

              <p-inputNumber formControlName="desiredAmount" styleClass="w-100" class="w-100 h-50" [min]="0"
                mode="currency" currency="MXN" locale="es-MX" prefix="MXN" />
            </div>
          </div>

          @if ( this.typeProperty.valid && this.m2.valid && this.bedrooms.valid && this.bathrooms.valid ) {
          <div class="col-6 offset-3 mt-4">
            <p-button label="Continuar" (onClick)="nextCallback.emit()" styleClass="rounded-5 w-100 py-3" />
          </div>
          }
        </div>
      </ng-template>
    </p-stepperPanel>

    <p-stepperPanel>
      <ng-template pTemplate="header" let-onClick="onClick" let-index="index">
        <p class="text-primary fs-5 m-0" (click)="onClick.emit()">Paso 3 de 3: Tus datos</p>
      </ng-template>
      <ng-template pTemplate="content" let-prevCallback="prevCallback" let-index="index">
        <p class="fw-bold fs-4 mb-4">Solo una cosa más... ¿Cómo podemos contactarte?</p>

        <div class="row">
          <div class="col-6">
            <div class="mb-3">
              <label for="name">Nombre completo <span class="text-danger">*</span></label>

              <p-inputGroup styleClass="w-100">
                <p-inputGroupAddon>
                  <i class="pi pi-user"></i>
                </p-inputGroupAddon>
                <input type="text" pInputText formControlName="name" class="w-100 h-50" />
              </p-inputGroup>
            </div>
          </div>
          <div class="col-6">
            <div class="mb-3">
              <label for="phone">Teléfono <span class="text-danger">*</span></label>

              <p-inputGroup styleClass="w-100">
                <p-inputGroupAddon>
                  <i class="pi pi-phone"></i>
                </p-inputGroupAddon>
                <p-inputGroupAddon>
                  +52
                </p-inputGroupAddon>
                <p-inputMask formControlName="phone" class="w-100" styleClass="w-100 h-50" placeholder="(999) 9999-999"
                  mask="(999) 9999-999" />
              </p-inputGroup>
            </div>
          </div>

          <div class="col-12">
            <div class="mb-3">
              <label for="email">Correo electrónico <span class="text-danger">*</span></label>

              <p-inputGroup styleClass="w-100">
                <p-inputGroupAddon>
                  <i class="pi pi-envelope"></i>
                </p-inputGroupAddon>
                <input type="text" pInputText formControlName="email" class="w-100 h-50" />
              </p-inputGroup>
            </div>
          </div>

          @if ( this.name.valid && this.phone.valid && this.email.valid ) {
          @if (this.form.invalid) {
          <div class="col-12">
            <div class="mb-3">
              <p class="m-0 text-danger fw-medium text-center" style="font-size: 11px;">¡Algo no quedó bien en tu
                formulario! Revisa de nuevo cada paso y dato para poder finalizar y trabajar en tu propuesta.</p>
            </div>
          </div>
          }

          <div class="col-6 offset-3 mt-4">
            <div class="mb-3 d-flex align-items-center justify-content-center gap-2">
              <div class="d-flex justify-content-center align-items-center gap-2">
                <input formControlName="termsConditions" class="form-check-input m-0" type="checkbox" value=""
                  id="termsConditions" style="width: 25px; height: 25px;">
                <label for="termsConditions" style="font-size: 12px;">Acepto los <a href="#">Términos y
                    Condiciones</a>.</label>
              </div>
            </div>

            <button class="btn btn-primary rounded-5 w-100 py-3" [disabled]="this.form.invalid"
              (click)="sendForm()">Finalizar</button>
          </div>
          }
        </div>
      </ng-template>
    </p-stepperPanel>
  </p-stepper>
</form>
}

@if (this.isSaving === true) {
<div class="d-flex flex-column align-items-center justify-content-center" style="height: 400px;">
  <div class="loader"></div>
  <p class="fs-5 m-0 mt-4">Estamos revisando tus datos, por favor espera...</p>
</div>
}

@if (this.isSaving === false && this.validationPurchaseTransaction !== null) {
@if (this.validationPurchaseTransaction.isValidPurchase === true) {
<div class="row">
  <div class="col-12">
    <p class="fw-bold fs-4 mb-0">Gracias por elegirnos.</p>
    <p class="fw-bold fs-4 mb-4">Valuremos tu oferta y pronto un asesor se comunicará contigo.</p>

    <!-- <p class="fw-bold fs-4 mb-4">Ahora, nosotros tenemos una pre oferta especial para ti.</p> -->
    <!-- <h2 class="display-1 fw-bold text-primary title-price">MXN {{this.validationPurchaseTransaction.amount | currency: 'MXN'}}</h2> -->

    <!-- <p class="text-muted m-0">La oferta está sujeto a cambios y valuación de la vivienda.</p> -->
    <!-- <p class="text-muted m-0">En seguida nos pondremos en contacto contigo, para llevar el proceso de tu oferta.</p> -->
    <!-- <p class="text-muted m-0">Te hemos enviado un correo electrónico con los detalles y el folio de seguimiento.</p> -->
  </div>
  <div class="col-6 offset-3 mt-4">
    <p-button label="Volver" (onClick)="resetForm()" styleClass="rounded-5 w-100 py-3" />
  </div>
</div>
}
@else {
<div class="row">
  <div class="col-12">
    <p class="fw-bold fs-4 mb-0">Gracias por elegirnos.</p>
    <p class="fw-bold fs-4 mb-4">Pero lamentamos no poder ofrecerte una oferta por tu inmueble.</p>
  </div>
  <div class="col-6 offset-3 mt-4">
    <p-button label="Volver" (onClick)="resetForm()" styleClass="rounded-5 w-100 py-3" />
  </div>
</div>
}
}