<a [routerLink]="['/propiedad', data.slug]" target="_blank" rel="noopener noreferrer" class="text-decoration-none">
  <article class="property-thumb card border-0 rounded-1 overflow-hidden h-100">
    <header class="card-header">
      @if (data.image?.name !== null && data.image?.name !== '') {
      <img src="{{data.image?.uri}}" class="card-img-top" alt="">
      }
      @else {
      <img src="./assets/images/media-placeholder.png" class="card-img-top" alt="">
      }
      <div class="tags d-flex flex-wrap gap-1">
        @if (data.foil !== null) {
        <span class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-primary">Folio:
          {{data.foil}}</span>
        }
        @if (data.status !== null) {
        <span
          class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-secondary">{{data.status}}</span>
        }
        @if (data.type !== null) {
        <span
          class="badge d-flex justify-content-center align-items-center lh-sm fw-normal bg-success">{{data.type}}</span>
        }
      </div>
      <div class="price">
        <p class="m-0"><span>MXN </span> <span>{{data.price | currency: 'MXN'}}</span></p>
      </div>
    </header>
    <main class="card-body">
      <p class="card-text text-truncate text-muted fw-normal mb-1"><i class="bi bi-geo-fill me-1"></i>
        <small>{{data.state}}, {{data.municipality}}.</small>
      </p>
      <p class="card-text m-0" title="{{data.metaGoogleMaps.formatted_address}}">
        {{data.metaGoogleMaps.formatted_address}}</p>
    </main>
    <footer class="card-footer">
      <p class="d-flex gap-1 m-0 text-muted m-0">
        <span>{{data.area}}m2 Área</span><span>-</span>
        <span>{{data.room}} Recam.</span><span>-</span>
        <span>{{data.bath}} Baños</span>
      </p>
    </footer>
  </article>
</a>
