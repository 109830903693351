import { Routes } from '@angular/router';
import { PropertyDetailsPage } from './property-details.component';

export const routes: Routes = [
  {
    path: 'propiedad/:slug',
    component: PropertyDetailsPage,
    title: '',
  },
];
