import { Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { NeedHelpComponent } from '@components/need-help/need-help.component';
import { OurServicesComponent } from '@components/our-services/our-services.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '@services/pages.service';

@Component({
  selector: 'app-vende',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    RouterLink,
    NeedHelpComponent,
    OurServicesComponent,
    NgbAccordionModule
  ],
  templateUrl: './vende.component.html',
  styleUrl: './vende.component.scss'
})
export class VendePage {
  private pageService = inject(PagesService)

  public pageData: any = []
  public hero: string = ''

  ngOnInit() {
    this.pageService.page(1).then((response: any) => {
      this.hero = response.hero.uri
      this.pageData = response.data
    })
  }
}
