import { Component, inject } from '@angular/core';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '@services/pages.service';

@Component({
  selector: 'app-help-center',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    NgbAccordionModule
  ],
  templateUrl: './help-center.component.html',
  styleUrl: './help-center.component.scss'
})
export class HelpCenterPage {
  private pageService = inject(PagesService)

  public pageData: any = []
  public hero: string = ''

  ngOnInit() {
    this.pageService.page(2).then((response: any) => {
      this.hero = response.hero.uri
      this.pageData = response.data
    })
  }
}
