import { Routes } from '@angular/router';
import { HomePage } from './home.component';

export const routes: Routes = [
  {
    path: 'inicio',
    component: HomePage,
    title: 'Inicio',
  },
];
