<header id="main-header">
  <nav class="navbar navbar-expand-lg">
    <div class="container flex-lg-row flex-nowrap align-items-center position-relative">
      <div class="navbar-brand w-100 m-0">
        <figure id="logotype" class="m-0 h-50" routerLink="/" role="button">
          <img src="./assets/images/logotype-color.svg" alt="Solviing Logotype" class="w-100 h-100">
        </figure>
      </div>

      <div class="w-100 d-none d-lg-block">
        <ng-container *ngTemplateOutlet="mainMenu"></ng-container>
      </div>

      <div class="navbar-other w-100 d-flex ms-auto">
        <ul class="navbar-nav flex-row align-items-center ms-auto">
          <li class="nav-item mx-2">
            <button type="button" class="btn btn-lg btn-outline-primary rounded-5 w-max-content"
              routerLink="/solviiforma">
              <small>Compramos tu casa</small>
            </button>
          </li>
          <li class="nav-item">
            <p-button icon="pi pi-bars" [text]="true" (click)="open(offcanvas)"></p-button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>

<ng-template #mainMenu>
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">Vende</a>
    </li>
    <!-- <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/propiedades" routerLinkActive="active">Compra</a>
    </li> -->
    <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/ayuda" routerLinkActive="active">Centro de ayuda</a>
    </li>
    <li class="nav-item">
      <a class="nav-link fw-medium w-max-content" routerLink="/ser-asesor" routerLinkActive="active">Ser asesor</a>
    </li>
  </ul>
</ng-template>

<ng-template #offcanvas let-offcanvas>
  <div class="offcanvas-header justify-content-between">
    <figure id="logotype" class="m-0" [routerLink]="'.'" style="width: 150px;">
      <img src="./assets/images/logotype-color.svg" alt="Logo">
    </figure>
    <p-button icon="pi pi-times" [rounded]="true" [text]="true" (click)="offcanvas.dismiss()"></p-button>
  </div>
  <div class="offcanvas-body d-flex flex-column h-100">
    <ng-container *ngTemplateOutlet="mainMenu"></ng-container>

    <div class="offcanvas-footer">
      <div>
        <a href="mailto:hello@example.com" class="link-inverse">{{'info@solviing.com'}}</a>
        <br> +(52) 56 1002 9410 <br>
        <nav class="nav social mt-4">
          <a role="button"><i class="bi bi-twitter-x"></i></a>
          <a role="button"><i class="bi bi-facebook"></i></a>
          <a role="button"><i class="bi bi-instagram"></i></a>
          <a role="button"><i class="bi bi-threads"></i></a>
          <a role="button"><i class="bi bi-tiktok"></i></a>
        </nav>
      </div>
    </div>
  </div>
</ng-template>