import { Routes } from '@angular/router';
import { PropiedadesPage } from './propiedades.component';

export const routes: Routes = [
  {
    path: 'propiedades',
    component: PropiedadesPage,
    title: 'Propiedades en venta'
  },
];
