<section id="map-properties">
  <main-header></main-header>

  <main class="wrapper">
    <section class="map">
      <gmap [mapCenter]="mapCenter" [mapMarkers]="mapMarkers" [mapClear]="mapClear"
        (onOpenInfoWindow)="onOpenInfoWindow($event)" (onCloseInfoWindow)="onCloseInfoWindow($event)"></gmap>
    </section>

    <section class="properties">
      <div class="filter p-3">
        <div class="d-flex gap-3 justify-content-between">
          <div class="d-flex gap-3">
            <p-button label="Filtros" icon="bi bi-sliders" styleClass="rounded-2"
              (click)="filterPanelShow = (filterPanelShow === false) ? true : false" />
          </div>

          <div class="d-flex gap-3">
            <p class="d-flex flex-column justify-content-center text-muted m-0 opacity-50">
              ({{propertiesListFilter.length}}) Resultados</p>
            <button type="button" class="btn" (click)="clearFilter()">
              <i class="bi bi-arrow-clockwise"></i> Limpiar busqueda
            </button>
            <!-- <p-selectButton
              [options]="[{ icon: 'bi bi-grid', value: 'grid' }, { icon: 'bi bi-list-task', value: 'list' }]"
              optionLabel="icon" optionValue="value" [(ngModel)]="styleListing">
              <ng-template let-item pTemplate>
                <i [class]="item.icon"></i>
              </ng-template>
            </p-selectButton> -->
          </div>
        </div>

        @if (filterPanelShow === true) {
        <div [formGroup]="filterForm" class="mt-3">
          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="location" [options]="locationsList" optionLabel="name" optionValue="value"
              showClear="true" filterBy="name" placeholder="Ubicación" styleClass="h-50"
              (onChange)="onFilterLocationChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>{{ selectedOption.name }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="pe-4">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="typeProperty" [options]="typePropertiesList" optionLabel="name"
              optionValue="value" showClear="true" filterBy="name" placeholder="Tipo de inmueble" styleClass="h-50"
              (onChange)="onFilterTypePropertyChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>{{ selectedOption.name }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="pe-4">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="rooms" [options]="roomsList" optionLabel="name" optionValue="value"
              showClear="true" filterBy="name" placeholder="Recamaras" styleClass="h-50"
              (onChange)="onFilterRoomChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>{{ selectedOption.name }} Recámaras</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="ps-2">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="bath" [options]="bathList" optionLabel="name" optionValue="value"
              showClear="true" filterBy="name" placeholder="Baños" styleClass="h-50" (onChange)="onFilterBathChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>{{ selectedOption.name }} Baños</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="ps-2">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="status" [options]="statusList" optionLabel="name" optionValue="value"
              showClear="true" filterBy="name" placeholder="Estatus" styleClass="h-50"
              (onChange)="onFilterStatusChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>{{ selectedOption.name }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="pe-4">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <div ngbDropdown class="d-inline-block"> <button type="button"
                class="btn h-50 bg-white border-1 border-dark-subtle" id="" ngbDropdownToggle>Precio:
                {{filterPrice.value[0] | currency: 'MXN'}} - {{filterPrice.value[1] | currency: 'MXN'}}</button>
              <div class="p-3" ngbDropdownMenu aria-labelledby="">
                <div class="p-3 mb-2">
                  <p-slider formControlName="price" [range]="true" [min]="0" [max]="4000000"
                    (onChange)="onFilterPriceChange()" />
                </div>
                <div class="d-flex gap-3">
                  <div class="form-floating" style="width: 170px;"> <input type="text" class="form-control border-1"
                      value="MXN {{filterPrice.value[0] | currency: 'MXN'}}" disabled> <label>Mínimo</label> </div>
                  <div class="form-floating" style="width: 170px;"> <input type="email" class="form-control border-1"
                      value="MXN {{filterPrice.value[1] | currency: 'MXN'}}" disabled> <label>Máximo</label> </div>
                </div>
              </div>
            </div>
          </div>

          <div class="d-inline-block me-2 mb-2">
            <p-dropdown formControlName="orderby" [options]="orderList" optionLabel="name" optionValue="value"
              filterBy="name" placeholder="Ordenar por" styleClass="h-50" (onChange)="onFilterOrderbyChange()">
              <ng-template pTemplate="selectedItem" let-selectedOption>
                <div>Ordenar por: {{ selectedOption.name }}</div>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <div class="pe-4">{{ item.name }}</div>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        }
      </div>
      <div class="px-3 pb-3">
        <div class="row g-3">
          @for (item of propertiesListFilter; track $index) {
          <div class="col-12 col-md-6">
            <property-thumb [data]="item"></property-thumb>
          </div>
          }
        </div>
      </div>
    </section>
  </main>
</section>
