import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'our-services',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './our-services.component.html',
  styleUrl: './our-services.component.scss'
})
export class OurServicesComponent {

}
