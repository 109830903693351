import { ApplicationConfig, LOCALE_ID } from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient } from '@angular/common/http';

import { registerLocaleData } from '@angular/common';
import localEsMx from '@angular/common/locales/es-MX';
registerLocaleData(localEsMx, 'es-MX');

import { routes } from './app.routes';
import { routes as home } from './pages/home/home.routes';
import { routes as vende } from './pages/vende/vende.routes';
import { routes as propiedades } from './pages/propiedades/propiedades.routes';
import { routes as propertyDetails } from './pages/property-details/property-details.routes';
import { routes as solviiforma } from './pages/solviiforma/solviiforma.routes';
import { routes as helpcenter } from './pages/help-center/help-center.routes';
import { routes as asesor } from './pages/asesor/asesor.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: "es-MX" },
    provideAnimations(),
    provideHttpClient(),

    provideRouter(home, withComponentInputBinding()),
    provideRouter(vende, withComponentInputBinding()),
    provideRouter(propiedades, withComponentInputBinding()),
    provideRouter(propertyDetails, withComponentInputBinding()),
    provideRouter(solviiforma, withComponentInputBinding()),
    provideRouter(helpcenter, withComponentInputBinding()),
    provideRouter(asesor, withComponentInputBinding()),

    provideRouter(routes, withComponentInputBinding()),
  ]
};
