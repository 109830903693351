import { Component } from '@angular/core';
import { MainFooterComponent } from '@components/main-footer/main-footer.component';
import { MainHeaderComponent } from '@components/main-header/main-header.component';
import { NeedHelpComponent } from '@components/need-help/need-help.component';
import { SolviiformaComponent } from '@components/solviiforma/solviiforma.component';

@Component({
  selector: 'app-solviiforma',
  standalone: true,
  imports: [
    MainHeaderComponent,
    MainFooterComponent,
    SolviiformaComponent,
    NeedHelpComponent
  ],
  templateUrl: './solviiforma.component.html',
  styleUrl: './solviiforma.component.scss'
})
export class SolviiformaPage {

}
