import { inject, Injectable } from '@angular/core';
import { HttpService } from '@utils/http.service';

@Injectable({
  providedIn: 'root'
})
export class PagesService {
  private http = inject(HttpService)

  public page = async (page: number) => await this.http.get(`/v1/dynamic-page/${page}`, false, false, false)
    .then((response: any) => response.data.content)
    .catch((error: any) => error)
}
