import { Routes } from '@angular/router';
import { VendePage } from './vende.component';

export const routes: Routes = [
  {
    path: '',
    component: VendePage,
    title: 'Solviing - Compramos tu casa en menos de 20 días.'
  },
];
