import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { GoogleMap, GoogleMapsModule, MapAdvancedMarker, MapCircle, MapInfoWindow, MapMarker } from '@angular/google-maps';

@Component({
  selector: 'gmap',
  standalone: true,
  imports: [
    GoogleMapsModule,
    MapInfoWindow,
    MapAdvancedMarker,
    MapCircle,
    CurrencyPipe
  ],
  templateUrl: './gmap.component.html',
  styleUrl: './gmap.component.scss'
})
export class GmapComponent {
  @Input() mapClear: boolean = false
  @Input() mapCenter!: google.maps.LatLngLiteral;
  @Input() mapMarkers: any[] = []

  @Output() onOpenInfoWindow = new EventEmitter<string>();
  @Output() onCloseInfoWindow = new EventEmitter<string>();

  @ViewChild('googleMap', { static: true }) googleMap!: GoogleMap
  @ViewChild(MapInfoWindow) infoWindow!: MapInfoWindow;

  public mapId = '2558f9905a1514c1';
  public mapZoom: any
  public mapOptions: google.maps.MapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    minZoom: 5,
    zoom: 5.4
  };
  public markerClustererImagePath = 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m';
  public markerClustererShow = true
  public infoWindowData: any = {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["mapCenter"]) {
      if (changes["mapCenter"].firstChange === false && this.mapClear === false) {
        this.mapZoom = 0;
        window.setTimeout(() => this.mapZoom = 11);
      }
    }

    if (changes["mapClear"]) {
      this.mapZoom = 0;
      window.setTimeout(() => this.mapZoom = this.mapOptions.zoom);
    }
  }

  zoomChanged() {
    let zoom = this.googleMap.getZoom()

    if (zoom != undefined) {
      if (zoom <= 10) {
        this.markerClustererShow = false
      } else {
        this.markerClustererShow = true
      }
    }
  }

  openInfoWindow(marker: MapAdvancedMarker | MapMarker, dataMarker: any) {
    this.infoWindow.open(marker);

    this.mapCenter = dataMarker.position
    this.infoWindowData = dataMarker

    this.onOpenInfoWindow.emit(this.infoWindowData.foil);
  }

  closeInfoWindow() {
    this.onCloseInfoWindow.emit(this.infoWindowData.foil);
    this.infoWindowData = {}
  }
}
