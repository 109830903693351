import { Routes } from '@angular/router';
import { HelpCenterPage } from './help-center.component';

export const routes: Routes = [
  {
    path: 'ayuda',
    component: HelpCenterPage,
    title: 'Centro de ayuda Solviing',
  },
];
