import { Component, inject } from '@angular/core'
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms'
import { CatalogService } from '@services/catalog.service'
import { RealEstateService } from '@services/realestate.service'
import { SolviiformaService } from '@services/solviiforma.service'
import { DropdownModule } from 'primeng/dropdown'
import { AutoCompleteModule } from 'primeng/autocomplete'
import { StepperModule } from 'primeng/stepper'
import { ButtonModule } from 'primeng/button'
import { InputTextModule } from 'primeng/inputtext'
import { InputNumberModule } from 'primeng/inputnumber'
import { InputSwitchModule } from 'primeng/inputswitch'
import { InputMaskModule } from 'primeng/inputmask'
import { InputGroupModule } from 'primeng/inputgroup'
import { InputGroupAddonModule } from 'primeng/inputgroupaddon'
import { CheckboxModule } from 'primeng/checkbox'
import { CurrencyPipe } from '@angular/common'

interface AutoCompleteCompleteEvent {
  originalEvent: Event
  query: string
}

interface ValidationPurchaseTransaction {
  isValidPurchase: boolean,
  amount: number
}

@Component({
  selector: 'solviiforma',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    DropdownModule,
    AutoCompleteModule,
    ButtonModule,
    StepperModule,
    InputTextModule,
    InputNumberModule,
    InputSwitchModule,
    InputMaskModule,
    InputGroupModule,
    InputGroupAddonModule,
    CheckboxModule,
    CurrencyPipe
  ],
  providers: [
    CatalogService,
    RealEstateService,
    SolviiformaService
  ],
  templateUrl: './solviiforma.component.html',
  styleUrl: './solviiforma.component.scss'
})
export class SolviiformaComponent {
  constructor(
    private formBuilder: FormBuilder,
    private catalogService: CatalogService,
    private realEstateService: RealEstateService,
    private solviiformaService: SolviiformaService
  ) {
    this.municipality?.disable()
    this.address?.disable()
  }

  public isSaving: boolean = false
  public validationPurchaseTransaction: ValidationPurchaseTransaction | null = null

  public states: object[] = []
  public municipalities: object[] = []
  public addressSuggestions: any[] = []
  public colonies: any[] = []
  public typeProperties: any[] = []

  public form = this.formBuilder.group({
    state: [null, Validators.required],
    municipality: [null, Validators.required],
    address: [null, Validators.required],
    placeId: [null, Validators.required],
    zipcode: [null, Validators.required],
    colony: [null, Validators.required],
    typeProperty: [null, Validators.required],
    m2: [null, Validators.required],
    bedrooms: [null, Validators.required],
    bathrooms: [null, Validators.required],
    debt: [null],
    amountDebt: [null],
    creditNumberInfonavit: [null],
    cadastralKeyNumber: [null],
    desiredAmount: [null],
    name: [null, Validators.required],
    email: [null, [Validators.required, Validators.email]],
    phone: [null, Validators.required],
    termsConditions: [false, Validators.requiredTrue],
  })

  get state() { return this.form.get('state') }
  set state(value: any) { this.state.setValue(value) }
  get municipality() { return this.form.get('municipality') }
  set municipality(value: any) { this.municipality.setValue(value) }
  get address() { return this.form.get('address') }
  set address(value: any) { this.address.setValue(value) }
  get placeId() { return this.form.get('placeId') }
  set placeId(value: any) { this.placeId.setValue(value) }
  get zipcode() { return this.form.get('zipcode') }
  set zipcode(value: any) { this.zipcode.setValue(value) }
  get colony() { return this.form.get('colony') }
  set colony(value: any) { this.colony.setValue(value) }
  get typeProperty() { return this.form.get('typeProperty') }
  set typeProperty(value: any) { this.typeProperty.setValue(value) }
  get m2() { return this.form.get('m2') }
  set m2(value: any) { this.m2.setValue(value) }
  get bedrooms() { return this.form.get('bedrooms') }
  set bedrooms(value: any) { this.bedrooms.setValue(value) }
  get bathrooms() { return this.form.get('bathrooms') }
  set bathrooms(value: any) { this.bathrooms.setValue(value) }
  get debt() { return this.form.get('debt') }
  set debt(value: any) { this.debt.setValue(value) }
  get amountDebt() { return this.form.get('amountDebt') }
  set amountDebt(value: any) { this.amountDebt.setValue(value) }
  get creditNumberInfonavit() { return this.form.get('creditNumberInfonavit') }
  set creditNumberInfonavit(value: any) { this.creditNumberInfonavit.setValue(value) }
  get cadastralKeyNumber() { return this.form.get('cadastralKeyNumber') }
  set cadastralKeyNumber(value: any) { this.cadastralKeyNumber.setValue(value) }
  get desiredAmount() { return this.form.get('desiredAmount') }
  set desiredAmount(value: any) { this.desiredAmount.setValue(value) }
  get name() { return this.form.get('name') }
  set name(value: any) { this.name.setValue(value) }
  get email() { return this.form.get('email') }
  set email(value: any) { this.email.setValue(value) }
  get phone() { return this.form.get('phone') }
  set phone(value: any) { this.phone.setValue(value) }
  get termsConditions() { return this.form.get('termsConditions') }
  set termsConditions(value: any) { this.termsConditions.setValue(value) }

  ngOnInit() {
    this.catalogService.getStates().then(response => response.map((item: any) => this.states.push({ name: item, value: item })))
    this.realEstateService.getTypes().then(response => response.content.map((item: any) => this.typeProperties.push({ name: item.label, value: item.propertyTypeId })))
  }

  async changeState() {
    if (this.state.value !== null) {
      await this.catalogService.getMunicipalities(this.state.value).then(response => {
        this.municipalities = []
        this.municipality = null
        this.address = null

        response.map((item: any) => this.municipalities.push({ name: item, value: item }))
      })

      this.municipality?.enable()
    } else {
      this.municipalities = []
      this.municipality = null
      this.address = null
      this.municipality?.disable()
      this.address?.disable()
    }
  }

  async changeMunicipality() {
    this.address = null

    if (this.municipality.value !== null) {
      this.address?.enable()
      this.changeZipcode()
    } else {
      this.address?.disable()
    }
  }

  async addressAutocomplete(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = []
    let state: any = {}
    let query = event.query

    await this.catalogService.getAddress(`${this.municipality.value}, ${this.state.value}, México`).then(response => state = response[0])

    let address = `${query}, ${this.municipality.value}, ${this.state.value}, México`
    let lat = state.geometry.location.lat
    let lng = state.geometry.location.lng

    await this.catalogService.getAddressAutocomplete(address, lat, lng).then(response => filtered = response)

    this.addressSuggestions = filtered
  }

  async onSelectAddress() {
    this.address = this.address.value.description

    await this.catalogService.getAddress(this.address.value).then(response => {
      if (response.length >= 1) {
        this.placeId = response[0].place_id

        this.zipcode = response[0].zip
        this.changeZipcode()
      }
    })
  }

  async changeZipcode() {
    let filtered: any[] = []
    this.colony = null

    if (this.zipcode.value !== null && this.zipcode.value !== '') {
      // Buscar colonias existentes por cp en nuestra plataforma
      await this.catalogService.getColoniesFeat3Prices(this.zipcode.value).then(response => {
        response = response.sort()
        response.map((item: any) => filtered.push(item.colonia))
      })

      // Buscar colonias por código postal
      await this.catalogService.getColonies(this.zipcode.value, 'zipcode').then(response => {
        response = response.sort()
        response.map((item: any) => filtered.push(item))
      })
    } else {
      if (this.municipality.value !== null) {
        // Buscar colonias por municipio
        await this.catalogService.getColonies(this.municipality.value).then(response => {
          response = response.sort()
          response.map((item: any) => filtered.push(item))
        })
      }
    }

    // Limpiar las colonias repetidas
    let result = filtered.filter((item, index) => filtered.indexOf(item) === index)
    filtered = []
    result.map((item: string) => filtered.push({ name: item, value: item }))

    this.colonies = filtered
  }

  async sendForm() {
    this.isSaving = true

    await this.solviiformaService.newLead(this.form.getRawValue()).then(response => {
      this.validationPurchaseTransaction = response

      setTimeout(() => this.isSaving = false, 1500);
    })
  }

  resetForm() {
    this.isSaving = false
    this.validationPurchaseTransaction = null
    this.municipalities = []
    this.addressSuggestions = []
    this.colonies = []

    this.municipality?.disable()
    this.address?.disable()

    this.form.reset();
  }
}
