import { Routes } from '@angular/router';
import { AsesorPage } from './asesor.component';

export const routes: Routes = [
  {
    path: 'ser-asesor',
    component: AsesorPage,
    title: 'Ser un asesor Solviing',
  },
];
